/**
 * Chat App Actions
 */
import {
  MOVE_SCRIPT_TO_FOLDER,
  ADD_SCENE,
  DELETE_SCENE,
  UPDATE_SCENE,
  GET_SUPERSCRIPTS,
  GET_FEATURED_SCRIPTS,
  SET_ACTIVE_SUPERSCRIPT,
  ERROR_FETCH_SUPERSCRIPT,
  UPDATE_SUPERSCRIPT,
  UPDATE_SUPERSCRIPT_LOCALLY,
  FETCH_SUPERSCRIPT,
  UPDATE_SCENES,
  IS_SAVING_SCRIPT,
  FETCH_MY_SUPERSCRIPTS,
  SET_ACTIVE_SCENE,
  RESET_ACTIVE_SCRIPT,
  SHOULD_FETCH_SUPERSCRIPT,
  SHOW_NEED_PERMISSION_FOR_SCRIPT,
  GENERATE_NEXT_SCENE_FOR_SCRIPT,
  REPLACE_SUPERSCRIPT,
  DELETE_SUPERSCRIPT,
  REPLACE_SCENE,
  FETCH_SCENES,
  UPDATE_MOST_RECENTLY_CREATED_SCRIPTS,
  SHOW_SHARE_MODAL,
  UPDATE_SCRIPT_EDITOR_SETTINGS,
  SET_SELECTED_ASSET_FOR_SCENE,
  ACTIVE_SCENE_DID_LEAVE_FOCUS,
  APPEND_TEXT_TO_SCRIPT_RESULT,
  PUSH_SCRIPT_TO_TOP_LATEST_SCRIPTS,
  UNDO_TEXT_TO_SCRIPT_RESULT,
  SET_ACTIVE_SCRIPT_RECIPE,
  RESET_ACTIVE_SCRIPT_RECIPE,
  FETCHING_USER_HOME_FOLDERS,
  FETCHED_USER_HOME_FOLDERS,
  SAVE_EXPANSION_STATE_FOR_FOLDER,
  ON_FOLDER_CREATED,
  ON_FOLDER_UPDATED,
  ON_FOLDER_DELETED,
  ON_SCRIPT_PERMISSIONS_SETTINGS_UPDATED,
  UPDATE_SELECTED_ASSET_FOR_SCENE,
  UPDATE_MAIN_CONTENT_SEARCH_QUERY,
  RESET_MAIN_CONTENT_SEARCH_QUERY,
  SHOW_MEDIA_PICKER_MODAL_FOR_SCRIPT,
  SHOW_MEDIA_PICKER_MODAL_FOR_SCENE,
  MEDIA_PICKER_WAS_REMOVED,
  SET_SHOW_BRIEF,
  UPDATAE_BRIEF_FOR_SCRIPT,
} from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
import { ScriptSceneManager } from "kre8tv/managers/VLScriptSceneManager";
import { SuperscriptManager } from "kre8tv/managers/VLSuperScriptManager";
import { AssetManager } from "kre8tv/managers/VLAssetManager";
import {
  ContentAsset,
  ResourceFolder,
  ScriptScene,
  Superscript,
} from "kre8tv/model";
import { NotificationManager } from "react-notifications";
import {
  fetchSuperscripts,
  textToScript,
  updateBriefWithPrompt,
  updateScriptFolder,
} from "services/superscripts.service";
import { store } from "store";
import firebase from "firebase";
import { getUserHomeFolders } from "services";
import { analytics } from "analytics/analytics";
import { SuperpowerManager } from "kre8tv/managers/VLSuperpowermanager";
import { autoCreateAssetForScene } from "services/superpowers.service";

const airtable = new AirtableManager();
const superscriptManager = new SuperscriptManager();
const scriptSceneManager = new ScriptSceneManager();
const assetManager = new AssetManager();

let debounces = {};
let delayDebounceFnUpdateScript;

var _ = require("lodash/core");

/**
 * Fetch all abilities
 */
export function fetchCurrentUserSuperscripts() {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_MY_SUPERSCRIPTS,
      });
      const fetchResults = await fetchSuperscripts();
      dispatch({
        type: GET_SUPERSCRIPTS,
        payload: fetchResults?.results,
      });
      return fetch;
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
}

/**
 * Fetch all abilities
 */
export const getFeaturedScripts = () => (dispatch) => {
  airtable.fetchFeaturedScripts((scripts, error) => {
    if (scripts) {
      dispatch({
        type: GET_FEATURED_SCRIPTS,
        payload: scripts,
      });
    } else {
      dispatch({
        type: GET_FEATURED_SCRIPTS,
        payload: error,
      });
    }
  });
};

/**
 * Fetch all recently created scripts
 */
export const getRecentlyCreatedScripts = () => {
  return async (dispatch) => {
    try {
      let scripts = await superscriptManager.fetchRecentlyCreatedScripts();
      if (scripts) {
        dispatch({
          type: UPDATE_MOST_RECENTLY_CREATED_SCRIPTS,
          payload: scripts,
        });
      } else {
        throw new Error("No scripts found");
      }
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
};

/**
 * Creates a new script (empty)
 */
export const createNewScript = () => {
  return async (dispatch) => {
    try {
      let script = new Superscript();
      const user_id = firebase.auth().currentUser?.uid;
      script.id = superscriptManager.uniqueScriptId();
      script.user_id = user_id;
      // console.log("createNewScript result:", script);
      dispatch({
        type: UPDATE_SUPERSCRIPT,
        payload: script,
      });
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
};

export const createNewScriptFromScratch = () => {
  return async (dispatch) => {
    try {
      let script = Superscript.newScript();
      const user_id = firebase.auth().currentUser?.uid;
      script.id = superscriptManager.uniqueScriptId();
      script.user_id = user_id;
      // console.log("createNewScript result:", script);
      // create a new scene
      let scene = new ScriptScene();
      scene.id = scriptSceneManager.uniqueSceneId();
      scene.script_id = script.id;
      scene.order = script.scenes.length;
      script.scenes.push(scene);

      //GET CURRENT ORGANIZATION ID (TO AUTO-ASSIGN TO SCRIPT)
      let current_organization_id =
        store.getState().accountDetailsReducer.account_details
          ?.organization_details?.id;

      if (current_organization_id) {
        script.organization_id = current_organization_id;
      }

      // console.log("createNewScriptFromScratch result:", script);
      analytics.scriptCreationEvent("empty_script", "success", {
        script_id: script.id,
        organization_id: script.organization_id ?? "none",
      });
      dispatch({
        type: UPDATE_SUPERSCRIPT,
        payload: script,
      });

      return script;
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
};

export const createNewScene = (script, setToActive, atIndex) => (dispatch) => {
  try {
    let scene = new ScriptScene();
    scene.id = scriptSceneManager.uniqueSceneId();
    scene.script_id = script.id;

    if (atIndex) {
      script.scenes.splice(atIndex, 0, scene);
    } else {
      script.scenes.push(scene);
    }

    // update order
    script.scenes.forEach((scene, index) => {
      scene.order = index;
    });

    // console.log(
    //   "create new scene - total scenes after adding:",
    //   script.scenes.length
    // );
    // console.log("create new scene - result:", scene);

    if (setToActive) {
      dispatch({
        type: SET_ACTIVE_SCENE,
        payload: scene,
      });
    }

    dispatch({
      type: UPDATE_SUPERSCRIPT,
      payload: script,
    });

    return scene;
  } catch (error) {
    // console.log("Create new scene error:", error);
    NotificationManager.error(error.message);
    return null;
  }
};

/**
 * Delete a script
 */
export const deleteScript = (script) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_SUPERSCRIPT,
        payload: script,
      });
      const result = await superscriptManager.deleteScript(script);
    } catch (error) {
      NotificationManager.error(error.message, "Error deleting story");
      return null;
    }
  };
};
/**
 * Duplicate a script
 */
export const duplicateScript = (scriptId, pushToLatest = false) => {
  return async (dispatch) => {
    try {
      const result = await superscriptManager.duplicateScript(scriptId);
      let script = result.script;
      script.scenes = result.scenes;
      dispatch({
        type: SET_ACTIVE_SUPERSCRIPT,
        payload: script,
      });
      if (pushToLatest) {
        dispatch(pushScriptToTopOfLatestScripts(script));
      }
      return Promise.resolve(script);
    } catch (error) {
      NotificationManager.error(error.message, "Error duplicating story");
      return Promise.reject(error);
    }
  };
};

/**
 * Merges two scripts.
 * @param {string} mergeScriptId - The ID of the script to merge.
 * @param {string} toScriptId - The ID of the script to merge into.
 */
export const mergeScripts = (mergeScriptId, toScriptId) => {
  return async (dispatch) => {
    try {
      const result = await superscriptManager.mergeScripts(
        mergeScriptId,
        toScriptId
      );
      NotificationManager.success("Scripts merged successfully");
    } catch (error) {
      NotificationManager.error(error.message, "Error merging scripts");
      return Promise.reject(error);
    }
  };
};

/**
 * Imports a script into another script
 * @param sourceScriptId The ID of the script to import from
 * @param targetScriptId The ID of the script to import into
 * @returns
 */
export const importScript = (sourceScriptId, targetScriptId) => {
  return async (dispatch) => {
    try {
      const result = await superscriptManager.importScript(
        sourceScriptId,
        targetScriptId
      );
      dispatch({
        type: UPDATE_SUPERSCRIPT,
        payload: result,
      });
    } catch (error) {
      NotificationManager.error(error.message, "Error importing story");
    }
  };
};
/**
 * Set the active superscript for the editor
 */
export const setActiveSuperscript =
  (superscript, fetchScenes = false) =>
  (dispatch) => {
    //print supescript and fetchScenes params
    // console.log("setActiveSuperscript ", superscript, fetchScenes);
    try {
      dispatch({
        type: SET_ACTIVE_SUPERSCRIPT,
        payload: superscript,
      });
      if (fetchScenes) {
        dispatch(fetchScenesForScript(superscript));
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error setting active story");
      return null;
    }
  };
/**
 * Just duplicates the script and doesn't set it to active or anything
 */
export const duplicateScriptPlain = (scriptId) => {
  return async (dispatch) => {
    //TODO: Implement
  };
};

export const resetActiveScript = () => (dispatch) => {
  //remove all keys from url
  // window.history.pushState({}, document.title, window.location.pathname);
  dispatch({ type: RESET_ACTIVE_SCRIPT });
};

export const shouldFetchScriptWithId = (scriptId) => (dispatch) => {
  // console.log("shouldFetchScriptWithId: ", scriptId);
  dispatch({ type: SHOULD_FETCH_SUPERSCRIPT, payload: scriptId });
};

export const showNeedPermissionForScript = (scriptId) => (dispatch) => {
  dispatch({ type: SHOW_NEED_PERMISSION_FOR_SCRIPT, payload: scriptId });
};

/**
 * Fetch a specific script
 */
export const fetchScript = (script_id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // console.log("fetchScript : fetchScript");

    dispatch({
      type: FETCH_SUPERSCRIPT,
      payload: script_id,
    });

    superscriptManager.fetchScript(script_id, (result, error) => {
      if (result) {
        //only set active if the current active script is the same as the one we just fetched
        if (
          store.getState().superscriptsReducer.isFetchingScriptId &&
          store.getState().superscriptsReducer.isFetchingScriptId === result.id
        ) {
          dispatch({
            type: SET_ACTIVE_SUPERSCRIPT,
            payload: result,
          });
        }
        resolve(result); // Resolve the promise with the result
      } else {
        // console.log("error - script - did not fetch", error);
        dispatch({
          type: ERROR_FETCH_SUPERSCRIPT,
          payload: null,
        });
        reject(error); // Reject the promise with the error
      }
    });
  });
};

export const pushScriptToTopOfLatestScripts = (script) => (dispatch) => {
  // console.log("pushScriptToTopOfLatestScripts : ", script);
  if (script.is_local) {
    // console.log("pushScriptToTopOfLatestScripts : is_local - ignoring");
    return;
  }
  dispatch({
    type: PUSH_SCRIPT_TO_TOP_LATEST_SCRIPTS,
    payload: script,
  });
};

/**
 * Fetch all abilities
 */
export const generateScript = (sourceUrl, sourceType) => async (dispatch) => {
  // console.log("generateScript : ", sourceUrl);

  dispatch({
    type: FETCH_SUPERSCRIPT,
  });

  const result = await superscriptManager.generateScript(sourceUrl, sourceType);
  if (result && result.results) {
    let script = result.results;

    //GET CURRENT ORGANIZATION ID (TO AUTO-ASSIGN TO SCRIPT)
    let current_organization_id =
      store.getState().accountDetailsReducer.account_details
        ?.organization_details?.id;
    if (current_organization_id) {
      script.organization_id = current_organization_id;
    }

    dispatch({
      type: SET_ACTIVE_SUPERSCRIPT,
      payload: script,
    });

    //fetch scenes

    const resultScript = await superscriptManager.fetchScenesForScript(script);
    dispatch({
      type: UPDATE_SCENES,
      payload: { scenes: resultScript.scenes, script: resultScript },
    });
  } else {
    // console.log("error - script - did not fetch", error);
    dispatch({
      type: ERROR_FETCH_SUPERSCRIPT,
      payload: null,
    });
    NotificationManager.error("Could not generate story");
  }
};

//save script (just use update script)
export const saveScript = (script) => async (dispatch) => {
  dispatch(updateScript(script));
};

export const saveScriptInBackground = (script) => async (dispatch) => {
  try {
    if (debounces[script.id]) clearTimeout(debounces[script.id]);
    const delayDebounceTime = 1000;
    const delayDebounceFnUpdateScript = setTimeout(async () => {
      try {
        // console.log("saveScriptInBackground - called:", script);
        // Update to is saving super script
        dispatch({
          type: IS_SAVING_SCRIPT,
          payload: script,
        });

        // Update it on the server
        const result = await superscriptManager.saveScript(
          script,
          script.scenes
        );

        // Handle result
        if (result) {
          const updatedScript = result;

          //see if this fixes the issue with the assets not updating
          script.scenes.forEach((scene, index) => {
            let existingScene = updatedScript.scenes.find(
              (s) => s.id === scene.id
            );
            if (existingScene) {
              //update the assets
              script.scenes[index].background_asset =
                existingScene.background_asset;
              script.scenes[index].content_asset_options =
                existingScene.content_asset_options;
            }
          });

          //NOTE - there is an issue here bc the assets are updated, but we are not updating it here.. (so maybe just update the assets for each scene)
          script.last_updated = updatedScript.last_updated;
          script.is_local = false;
          script.id = updatedScript.id;

          if (
            script.id === store.getState().superscriptsReducer.activeScript.id
          ) {
            dispatch({
              type: UPDATE_SUPERSCRIPT,
              payload: script,
            });
          } else {
            console.log("saveScriptInBackground - not updating active script");
          }
          return updatedScript; // Return the updated script
        } else {
          throw new Error("Could not save story");
        }
      } catch (error) {
        console.error("Error saving script:", error);
        return null; // Return null if there's an error
      }
    }, delayDebounceTime);

    debounces[script.id] = delayDebounceFnUpdateScript;
  } catch (error) {
    console.error("Error in saveScriptInBackground:", error);
    return null; // Return null if there's an error
  }
};

/**
 * Updates the script locally and then remotely
 * @param {*} script The script to update / save
 * @returns
 */
export const updateScript = (script) => async (dispatch) => {
  //update it locally
  dispatch({
    type: UPDATE_SUPERSCRIPT_LOCALLY,
    payload: script,
  });

  //notify on update (to update flags, script status)
  dispatch({
    type: IS_SAVING_SCRIPT,
    payload: script,
  });

  if (debounces[script.id]) clearTimeout(debounces[script.id]);

  delayDebounceFnUpdateScript = setTimeout(async () => {
    try {
      //update it on server
      const result = await superscriptManager.saveScript(script);
      //handle result
      let scriptResult = result;
      dispatch({
        type: UPDATE_SUPERSCRIPT,
        payload: scriptResult,
      });
      if (scriptResult) {
        NotificationManager.success("Script successfully saved");
      }
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  }, 4000);

  debounces[script.id] = delayDebounceFnUpdateScript;
};

/**
 * Updates the script locally and then remotely
 * @param {*} script The script to update / save
 * @returns
 */
export const saveOrUpdateSuperScriptAndScenes =
  (script, scenes) => async (dispatch) => {
    //notify on update (to update flags, script status)
    dispatch({
      type: IS_SAVING_SCRIPT,
      payload: script,
    });

    if (debounces[script.id]) clearTimeout(debounces[script.id]);

    delayDebounceFnUpdateScript = setTimeout(async () => {
      try {
        //update it on server
        const result = await superscriptManager.saveScript(script, scenes);
        //handle result
        let scriptResult = result;
        //  update locally
        dispatch({
          type: UPDATE_SUPERSCRIPT,
          payload: scriptResult,
        });
        if (scriptResult) {
          NotificationManager.success("Script successfully saved");
        }
      } catch (error) {
        NotificationManager.error(error.message);
        //  update locally
        script.scenes = scenes;
        dispatch({
          type: UPDATE_SUPERSCRIPT,
          payload: script,
        });
        return null;
      }
    }, 4000);

    debounces[script.id] = delayDebounceFnUpdateScript;
  };

export const forkScript = (script) => async (dispatch) => {
  try {
    //update it on server
    const result = await superscriptManager.forkScript(script, script.scenes);
    //handle result
    let scriptResult = result;
    //  update locally
    dispatch({
      type: UPDATE_SUPERSCRIPT,
      payload: scriptResult,
    });
    if (scriptResult) {
      NotificationManager.success("Script successfully saved");
    }
    return scriptResult;
  } catch (error) {
    NotificationManager.error(error.message);
    return null;
  }
};

/**
 * Updates the script locally and then remotely
 * @param {*} script The script to update / save
 * @returns
 */
export const updateScriptLocally = (script) => async (dispatch) => {
  //update it locally
  dispatch({
    type: UPDATE_SUPERSCRIPT_LOCALLY,
    payload: script,
  });
};

/**
 * Creates or updates the scene and assign to the script
 * @param {*} script
 * @param {*} scene
 * @returns
 */
export const fetchScenesForScript = (script) => async (dispatch) => {
  dispatch({
    type: FETCH_SCENES,
    payload: {},
  });
  const resultScript = await superscriptManager.fetchScenesForScript(script);
  dispatch({
    type: UPDATE_SCENES,
    payload: { scenes: resultScript.scenes, script: resultScript },
  });
};

/**
 * Creates or updates the scene and assign to the script
 * @param {*} script
 * @param {*} scene
 * @returns
 */
export const addSceneToScript = (script, scene, index) => async (dispatch) => {
  // update locally
  scene.id = scriptSceneManager.uniqueSceneId();
  scene.scene_index = index;
  scene.script_id = script.id;
  dispatch({
    type: ADD_SCENE,
    payload: scene,
  });

  try {
    const resultScene = await superscriptManager.saveScene(scene, script);
    dispatch({
      type: UPDATE_SCENE,
      payload: resultScene,
    });
  } catch (error) {
    NotificationManager.error(error.message);
    return null;
  }
};

/**
 * Update and save the scene for this script
 * @param {*} script
 * @param {*} scene
 * @returns
 */
export const updateSceneForScript = (script, scene, index) => (dispatch) => {
  if (index) scene.scene_index = index;
  dispatch({
    type: UPDATE_SCENE,
    payload: scene,
  });
};

export const duplicateScene = (script, scene, index) => async (dispatch) => {
  let duplicated = scriptSceneManager.duplicateScene(scene);
  //update scene index
  duplicated.scene_index = index + 1;
  let start = index + 1;
  let deleteCount = 0;
  //insert into scenes array
  const scenes = script.scenes;
  scenes.splice(start, deleteCount, duplicated);
  script.scenes = scenes;

  //update index of rest of scenes
  dispatch(updateAllScenesForScript(script, script.scenes));

  //update store
  dispatch({
    type: UPDATE_SCENES,
    payload: { scenes: scenes, script: script },
  });

  try {
    //save scene to backend
    const resultScene = await superscriptManager.saveScene(duplicated, script);
    dispatch({
      type: UPDATE_SCENE,
      payload: resultScene,
    });
  } catch (error) {
    NotificationManager.error(error.message);
    return null;
  }
};

export const deleteSceneFromScript =
  (script, scene, index) => async (dispatch) => {
    /* remove scene from store */
    dispatch({
      type: DELETE_SCENE,
      payload: scene,
    });

    //delete scene from DB
    // const deleteScene = await superscriptManager.deleteScene(scene);

    //update rest of scenes to correct index
    dispatch(updateAllScenesForScript(script, script.scenes));
  };

export const updateAllScenesForScript = (script, scenes) => (dispatch) => {
  for (let index = 0; index < scenes.length; index++) {
    dispatch(updateSceneForScript(script, scenes[index], index));
  }
};

export const updateActiveScene = (scene) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SCENE,
    payload: scene,
  });
};

export const activeSceneDidLeaveFocus = (scene) => (dispatch) => {
  dispatch({
    type: ACTIVE_SCENE_DID_LEAVE_FOCUS,
    payload: scene,
  });
};

export const removeAssetForScene = (scene, layer) => async (dispatch) => {
  switch (layer) {
    case "foreground":
      scene.foreground_asset = null;
      scene.foreground_asset_id = null;
      break;
    case "background":
      scene.background_asset = null;
      scene.background_asset_id = null;
      break;
    case "audio":
      scene.audio_asset = null;
      scene.audio_asset_id = null;
      break;
    default:
      break;
  }

  //3. save scene
  const updatedScene = await superscriptManager.saveScene(scene, null);
  dispatch({
    type: UPDATE_SCENE,
    payload: updatedScene,
  });
};

export const updateTextForScene = (text, scene) => (dispatch) => {
  scene.text_speech = text;
  //update locally
  dispatch({
    type: UPDATE_SCENE,
    payload: scene,
  });
};

export const removeAssetForSceneNew =
  (scene, asset, optionIndex = -1) =>
  (dispatch) => {
    scene.removeAsset(asset);
    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });
  };

/**
 * Updates the uploaded asset for a specific scene identified by its ID.
 *
 * @param {string} scene_id - The ID of the scene to update.
 * @param {Object} contentAsset - The asset to be uploaded and assigned to the scene.
 * @returns {function} - A dispatch function to update the scene in the store.
 */
export const updateUploadedAssetForSceneId =
  (scene_id, contentAsset) => (dispatch) => {
    //1. see if this scene exists in the script
    const activeScript = store.getState().superscriptsReducer.activeScript;
    const scenes = activeScript.scenes ?? [];
    // //attempt to find index of scene
    const sceneIndex = scenes.findIndex((scene) => scene.id === scene_id);
    if (sceneIndex === -1) {
      //scene not found
      return;
    }
    //2. Assign to scene
    const scene = scenes[sceneIndex];
    scene.updateWithSelectedAsset(contentAsset, -1);
    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });

    //trigger update script in background to save
    dispatch(saveScriptInBackground(activeScript));
  };

//same for script id
export const updateUploadedAssetForScriptId =
  (script_id, contentAsset) => (dispatch) => {
    //1. see if this is the active script
    const activeScript = store.getState().superscriptsReducer.activeScript;
    if (activeScript.id !== script_id) {
      return;
    }
    // otheriwse, push this asset to the script
    activeScript.script_assets.push(contentAsset);

    dispatch({
      type: UPDATE_SUPERSCRIPT,
      payload: activeScript,
    });

    //trigger update script in background to save
    dispatch(saveScriptInBackground(activeScript));
  };

export const updateAssetForSceneNew =
  (scene, contentAsset, optionIndex = -1) =>
  async (dispatch) => {
    contentAsset.scene_id = scene.id;
    scene.updateWithSelectedAsset(contentAsset, optionIndex);

    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });
  };

export const updateSelectedAssetForScene =
  (scene, contentAsset) => (dispatch) => {
    scene.selected_content_asset = contentAsset;

    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });
  };

export const updateAssetForScene =
  (scene, contentAsset) => async (dispatch) => {
    //2. Assign to scene
    switch (contentAsset.layer) {
      case "foreground":
        scene.foreground_asset = contentAsset;
        break;
      case "background":
        scene.background_asset = contentAsset;
        break;
      case "audio":
        scene.audio_asset = contentAsset;
        break;

      default:
        break;
    }

    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });

    contentAsset.scene_id = scene.id;

    //1. Upload/save asset
    // const updatedAsset = await assetManager.saveOrUpdateAsset(contentAsset);
    // if (!updatedAsset) {
    //   //error!
    //   NotificationManager.error("Could not save asset");
    // }

    //2. Assign to scene
    switch (contentAsset.layer) {
      case "foreground":
        scene.foreground_asset = contentAsset;
        scene.foreground_asset_id = contentAsset.id;
        break;
      case "background":
        scene.background_asset = contentAsset;
        scene.background_asset_id = contentAsset.id;
        break;
      case "audio":
        scene.audio_asset = contentAsset;
        scene.audio_asset_id = contentAsset.id;
        break;

      default:
        break;
    }

    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });

    //3. Update scene on DB
    // const resultScene = await superscriptManager.saveScene(scene, null);
    // dispatch({
    //   type: UPDATE_SCENE,
    //   payload: resultScene,
    // });
  };

/**
 * Save a new audio url for the scene
 * @param {*} scene The scene in question
 * @param {*} audioUrl Remote URL of file
 * @param {*} localUrl Local URL of file
 * @returns
 */
export const updateAudioUrlForScene =
  (scene, audioUrl, localUrl) => async (dispatch) => {
    //update the scene
    scene.temp_audio_asset_url = null; //clear it
    //remove from existing

    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });

    //1. Upload/save asset
    let contentAsset = new ContentAsset();
    contentAsset.type = "audio";
    contentAsset.layer = "audio";
    contentAsset.url = audioUrl;
    contentAsset.source_url = audioUrl;
    contentAsset.scene_id = scene.id;

    //save the asset on DB
    const save = await assetManager.saveOrUpdateAsset(contentAsset);

    if (!save) {
      //error!
      NotificationManager.error("Could not save asset");
    }

    //2. Assign to scene
    switch (contentAsset.layer) {
      case "foreground":
        scene.foreground_asset = contentAsset;
        scene.foreground_asset_id = contentAsset.id;
        break;
      case "background":
        scene.background_asset = contentAsset;
        scene.background_asset_id = contentAsset.id;
        break;
      case "audio":
        scene.audio_asset = contentAsset;
        scene.audio_asset_id = contentAsset.id;
        break;

      default:
        break;
    }

    const resultScene = await superscriptManager.saveScene(scene, null);
    dispatch({
      type: UPDATE_SCENE,
      payload: resultScene,
    });
  };

export const generateNextSceneForScript = (script) => (dispatch) => {
  dispatch({
    type: GENERATE_NEXT_SCENE_FOR_SCRIPT,
    payload: script,
  });
};

export const updateScriptScheduleDate = (script, scheduleDate) => {
  script.scheduled_for = scheduleDate.getTime();

  return async (dispatch) => {
    const result = await superscriptManager.saveScript(script);
    let scriptResult = result.results;
    dispatch({
      type: REPLACE_SUPERSCRIPT,
      payload: scriptResult,
    });
  };
};

export const replaceSceneForScript = (scene) => (dispatch) => {
  dispatch({
    type: REPLACE_SCENE,
    payload: scene,
  });
};

export const showSceneError = (error) => {
  NotificationManager.error(error, "Scene error");
};

export const showShareScriptModal = (show) => (dispatch) => {
  dispatch({
    type: SHOW_SHARE_MODAL,
    payload: show,
  });
};

export const getScriptFromId = (scriptId, scripts) => {
  const result = scripts.filter((script) => script.id === scriptId);
  if (result.length > 0) {
    return result[0];
  } else {
    return null;
  }
};

export const fireTextToScript = (script, prompt) => async (dispatch) => {
  // console.log("fireTextToScript", script, prompt);
  const result = await textToScript(script, prompt);
  if (result) {
    const resultScriptJSON = result.results;
    const resultScript = new Superscript(resultScriptJSON);
    dispatch({
      type: APPEND_TEXT_TO_SCRIPT_RESULT,
      payload: {
        prompt: prompt,
        originalScript: script,
        resultScript: resultScript,
      },
    });
    return resultScript;
  } else {
    return null;
  }
};

export const fireUpdateBriefWithPrompt =
  (script, prompt, brief) => async (dispatch) => {
    const result = await updateBriefWithPrompt(script.id, prompt, brief);
    if (result) {
      return result;
    } else {
      return null;
    }
  };

export const undoTextToScript = (replaceWithScript) => (dispatch) => {
  dispatch({
    type: UNDO_TEXT_TO_SCRIPT_RESULT,
    payload: replaceWithScript,
  });
};

export const updateBriefForScript = (script) => (dispatch) => {
  dispatch({
    type: UPDATAE_BRIEF_FOR_SCRIPT,
    payload: script,
  });
};

export const setActiveScriptRecipe = (recipe) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SCRIPT_RECIPE,
    payload: recipe,
  });
};

export const resetActiveScriptRecipe = () => (dispatch) => {
  dispatch({
    type: RESET_ACTIVE_SCRIPT_RECIPE,
  });
};

//MARK: FOLDERS

export const getCurrentUserHomeFolders = () => async (dispatch) => {
  dispatch({
    type: FETCHING_USER_HOME_FOLDERS,
  });
  try {
    const result = await getUserHomeFolders();
    dispatch({
      type: FETCHED_USER_HOME_FOLDERS,
      payload: result,
    });
  } catch (error) {
    NotificationManager.error("Error fetching user home folders");
    dispatch({
      type: FETCHED_USER_HOME_FOLDERS,
      payload: {},
    });
  }
};

export const moveScriptToFolder =
  (script, folder, atIndex) => async (dispatch) => {
    const target_folder_id = folder?.id ?? null;
    const original_folder_id = script.folder_id;
    try {
      // console.log("moveScriptToFolder", script, folder, atIndex);
      dispatch({
        type: MOVE_SCRIPT_TO_FOLDER,
        payload: { script, folder, atIndex },
      });
      //update expansion state to be open
      if (folder) {
        dispatch({
          type: SAVE_EXPANSION_STATE_FOR_FOLDER,
          payload: { folder, expanded: true },
        });
      }
      //update in background
      const result = await updateScriptFolder(script.id, target_folder_id);
      if (result) {
        // NotificationManager.success("Script moved to " + folder.name);
      } else {
        //revert?
        throw new Error("Could not move story");
      }
    } catch (error) {
      console.error("moveScriptToFolder error", error);
      NotificationManager.error("Failed moving story");
      //todo - revert
      const originalFolder = new ResourceFolder({ id: original_folder_id });
      dispatch({
        type: MOVE_SCRIPT_TO_FOLDER,
        payload: { script, originalFolder, atIndex },
      });
    }
  };

export const saveExpansionStateForFolder = (folder, expanded) => (dispatch) => {
  dispatch({
    type: SAVE_EXPANSION_STATE_FOR_FOLDER,
    payload: { folder, expanded },
  });
};

export const onFolderCreated = (folder) => (dispatch) => {
  dispatch({
    type: ON_FOLDER_CREATED,
    payload: folder,
  });
};

export const onFolderUpdated = (folder) => (dispatch) => {
  dispatch({
    type: ON_FOLDER_UPDATED,
    payload: folder,
  });
};

export const onFolderDeleted = (folder) => (dispatch) => {
  dispatch({
    type: ON_FOLDER_DELETED,
    payload: folder,
  });
};

//Script permissions

export const onScriptPermissionsSettingsUpdated =
  (script, permissionSettings) => (dispatch) => {
    dispatch({
      type: ON_SCRIPT_PERMISSIONS_SETTINGS_UPDATED,
      payload: { script, permissionSettings },
    });
  };

/**
 * Update the script editor settings
 * @param {*} enhanceText True if we should enhance the text
 * @param {*} prompt Prompt to use
 * @param {*} autoCreateAssets True if we should auto create assets
 * @param {*} mode Mode to use ("ai" | "stock")
 * @param {*} aiSettings An object containing the keys modelId, modelName, modelPromptPrefix
 * @returns
 */
export const updateScriptEditorSettings =
  (
    scriptId,
    enhanceTextEnabled,
    prompt,
    autoCreateAssetsEnabled,
    mode,
    aiSettings
  ) =>
  (dispatch) => {
    const settings = {
      enhanceText: { enabled: enhanceTextEnabled, prompt: prompt },
      autoCreateAssets: {
        enabled: autoCreateAssetsEnabled,
        mode,
        ai: aiSettings,
      },
    };
    dispatch({
      type: UPDATE_SCRIPT_EDITOR_SETTINGS,
      payload: { scriptId, settings },
    });
  };

export const autoEnhanceSceneText = (scene, prompt) => async (dispatch) => {
  //for now don't call remote, just use dummy but we need to basically update the scene text with the enhanced text (for now use lorem ipsum)
  // const enhancedText = await autoEnhanceText(prompt);
  const enhancedText = "Test enhanced text";
  if (enhancedText) {
    scene.text_speech = enhancedText;
    dispatch({
      type: UPDATE_SCENE,
      payload: scene,
    });
  }
};

//todo - expose new endpoint for all "auto enahnce" scene stuff...
export const autoCreateSceneMedia = (
  script,
  scene,
  method,
  modelId,
  modelPromptPrefix
) => {
  return async (dispatch) => {
    try {
      // console.log("actions - autoCreateSceneMedia", scene.id, method, modelId);
      const fetch = await autoCreateAssetForScene(
        scene.id,
        scene.text_speech,
        method,
        modelId,
        modelPromptPrefix
      );
      if (fetch && fetch.assets) {
        const assets = fetch.assets;
        if (assets.length > 0) {
          const asset = assets[0];
          dispatch(updateAssetForSceneNew(scene, asset));
          dispatch(saveScriptInBackground(script));
          return asset;
        } else {
          throw new Error("Returned assets array is empty");
        }
      } else {
        throw new Error("Could not complete auto create asset");
      }
    } catch (error) {
      console.error("superscript actions - autoCreateSceneMedia error", error);
      NotificationManager.error("Could not auto create asset");
      return null;
    }
  };
};

export const populateScenesWithAssets =
  (scenesAndAssetsPayload) => (dispatch) => {
    //1. Get existing scenes from the Redux store
    const activeScript = store.getState().superscriptsReducer.activeScript;
    const activeScenes = store.getState().superscriptsReducer.activeScenes;
    //2. For each scene_id in the payload, if there is an asset for that scene, add it to the front
    const updatedScenes = activeScenes.map((scene) => {
      const sceneId = scene.id;
      // Find the scene in active scenes
      const sceneIndex = scenesAndAssetsPayload.findIndex(
        (s) => s.scene_id === sceneId
      );
      if (sceneIndex === -1) {
        return scene;
      }
      const sceneWithAssets = scenesAndAssetsPayload[sceneIndex];
      const asset = sceneWithAssets.content_asset;
      if (asset) {
        scene.updateWithSelectedAsset(asset, -1);
      }
      return scene;
    });
    //3. update the scenes
    dispatch({
      type: UPDATE_SCENES,
      payload: { scenes: updatedScenes, script: activeScript },
    });
  };

export const updateMainContentSearchQuery = (query) => ({
  type: UPDATE_MAIN_CONTENT_SEARCH_QUERY,
  payload: query,
});

export const resetMainContentSearchQuery = () => ({
  type: RESET_MAIN_CONTENT_SEARCH_QUERY,
});

export const showMediaPickerModalForScript = () => (dispatch) => {
  dispatch({
    type: SHOW_MEDIA_PICKER_MODAL_FOR_SCRIPT,
  });
};

export const showMediaPickerModalForScene = () => (dispatch) => {
  dispatch({
    type: SHOW_MEDIA_PICKER_MODAL_FOR_SCENE,
  });
};

export const mediaPickerWasRemoved = () => (dispatch) => {
  dispatch({
    type: MEDIA_PICKER_WAS_REMOVED,
  });
};

export const setShowBriefStateForScript =
  (showBrief, forScriptId) => (dispatch) => {
    dispatch({
      type: SET_SHOW_BRIEF,
      payload: { show_brief: showBrief, script_id: forScriptId },
    });
  };
