import { ContentAsset } from "./content-asset.model";
import { IEntity } from "./interfaces/entity.interface";
import { ScriptScene } from "./script-scene.model";

/**
 * Permission settings for a script
 */
export class ScriptPermissionSettings {
  access: "public" | "private" | "unlisted" = "public";
  permission: "owner" | "editor" | "viewer" = "viewer";

  constructor(obj?: any) {
    this.access = obj?.access ?? "public";
    this.permission = obj?.permission ?? "viewer";
  }
}

export class Superscript implements IEntity {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * title of script
   */
  title = "";
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id: string = "";

  /**
   * Last updated date
   */
  last_updated: number = 0;
  /**
   * Created on date
   */
  created_on: number = 0;

  /**
   * id of format (optional)
   */
  format_id?: string;

  /**
   * name of format (optional)
   */
  format_name?: string;

  /**
   * Type of script - normal, viral, evergreen
   */
  type?: string;

  /**
   * Thumbnail url
   */
  thumbnail_url?: string;

  /**
   * Path to take to on click
   */
  path?: string;

  /**
   * Path to take to on click
   */
  scenes: Array<ScriptScene> = [];

  /**
   * Niche (category)
   */
  niche?: string;

  /**
   * Audience (who its targeted for more specifically)
   */
  audience?: string;

  /**
   * Topic its about
   */
  topic?: string;

  /**
   * The name of the style of the script
   */
  style?: string;

  /**
   * Schedule for timestamp
   */
  scheduled_for?: number;

  /**
   * Is script deleted
   */
  is_deleted = false;
  /**
   * Is script deleted
   */
  number_of_scenes?: number;

  /**
   * Script permissions
   */
  permissions: Record<string, ScriptPermissions> = {};

  /**
   * The script recipe id it was created from
   */
  created_from_script_recipe_id?: string;

  /**
   * Is script featured
   */
  featured = false;

  /**
   * Is script featured
   */
  source_url?: string;

  /**
   * tags
   */
  tags: string[] = [];
  /**
   * human like summary of story of script
   */
  summary?: string;

  /**
   * Get the parent script this was forked from (if exists)
   */
  forked_from_script_id?: string;
  /**
   * username of author
   */
  author_username?: string;

  /**
   * Is script local
   */
  is_local = false;

  /**
   * Folder id this script belongs to
   */
  folder_id?: string;

  /**
   * The organization id / team this script belongs to
   */
  organization_id?: string;

  /**
   * Permission settings for a script
   */
  permission_settings: ScriptPermissionSettings =
    new ScriptPermissionSettings();

  //local flag which will be set to true if the user can edit the script
  can_edit = true;

  script_info_message?: string;
  capture_info_message?: string;
  request_upload = false;
  metadata?: any;

  script_assets: ContentAsset[] = [];

  /**
   * Brief description of the script (optional)
   */
  brief?: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;
    this.title = obj.title;
    this.user_id = obj.user_id;
    this.last_updated_by_user_id = obj.last_updated_by_user_id;

    //details
    this.format_id = obj.format_id;
    this.format_name = obj.format_name;
    this.type = obj.type;
    this.thumbnail_url = obj.thumbnail_url;
    this.path = obj.path;
    this.is_deleted = obj.is_deleted ?? false;

    this.niche = obj.niche;
    this.audience = obj.audience;
    this.topic = obj.topic;
    this.style = obj.style;
    this.is_deleted = obj.is_deleted ?? false;
    this.number_of_scenes = obj.number_of_scenes;

    //dates
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;
    this.last_updated_by_user_id = obj.user_id;
    this.scheduled_for = obj.scheduled_for;

    //scenes
    this.scenes = new Array<ScriptScene>();
    if (obj.scenes && obj.scenes.length > 0) {
      //parse scenes
      obj.scenes.forEach((scene: any) => {
        this.scenes.push(new ScriptScene(scene));
      });
    }
    this.permissions = obj.permissions ?? this.permissions;

    //extra
    this.created_from_script_recipe_id = obj.created_from_script_recipe_id;
    this.source_url = obj.source_url;
    this.tags = obj.tags;

    this.summary = obj.summary;
    this.author_username = obj.author_username;
    this.forked_from_script_id = obj.forked_from_script_id;
    this.folder_id = obj.folder_id;
    this.organization_id = obj.organization_id;

    //notes
    this.script_info_message = obj.script_info_message;
    this.capture_info_message = obj.capture_info_message;

    this.permission_settings = new ScriptPermissionSettings(
      obj.permission_settings
    );

    this.request_upload = obj.request_upload ?? false;
    this.metadata = obj.metadata ?? {};

    const script_assets = obj.script_assets ?? [];
    this.script_assets = [];
    script_assets.forEach((element: any) => {
      const asset = ContentAsset.fromJSON(element);
      if (asset) {
        this.script_assets.push(asset);
      }
    });

    // Initialize new brief property
    this.brief = obj?.brief; // Optional property initialization
  }

  createdOnString(): string {
    let date = new Date(this.created_on);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  lastUpdatedString(): string {
    let date = new Date(this.last_updated);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  isSaved(): boolean {
    return this.id?.length > 0;
  }

  /**
   * The requested upload quality for when a user uploads a video
   * @returns Compressed or HD
   */
  requestedUploadQuality(): "compressed" | "hd" {
    return this.metadata?.requested_upload_quality ?? "compressed";
  }

  requestedUploadQualityIdString(): string {
    return this.request_upload && this.requestedUploadQuality()
      ? this.requestedUploadQuality()
      : "none";
  }

  toJSON(): any {
    var json: any = {};
    json["id"] = this.id;
    json["user_id"] = this.user_id;
    json["title"] = this.title;
    json["thumbnail_url"] = this.thumbnail_url;
    json["tags"] = this.tags;
    json["scenes"] = this.scenes.map((scene) => scene.toJSON());
    json["folder_id"] = this.folder_id;
    json["organization_id"] = this.organization_id;
    return json;
  }

  static newScript(folder_id?: string): Superscript {
    let script = new Superscript();
    script.id = "";
    script.title = "";
    script.user_id = "";
    script.last_updated_by_user_id = "";
    const now = new Date().getTime();
    script.last_updated = now;
    script.created_on = now;
    script.scenes = new Array<ScriptScene>();
    script.permissions = {};
    script.is_local = true;
    script.folder_id = folder_id;
    return script;
  }
}
